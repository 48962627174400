import { NextPage } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import * as React from 'react';
import { COUNTRIES_OPTIONS } from 'src/modules/address/constants';
import Location from 'src/modules/address/views/Location';

const namespacesRequired = ['common', 'location', 'landing', 'not-found'];

const LocationPage: NextPage = () => {
  return (
    <>
      <div className="m-auto flex  flex-col items-center justify-center">
        <Location />
      </div>
    </>
  );
};

export async function getStaticPaths() {
  const params = COUNTRIES_OPTIONS?.map(item => ({
    params: { countryCode: item?.code },
  }));

  return {
    paths: params,
    fallback: true,
  };
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, namespacesRequired)),
    },
    revalidate: 1000,
  };
}
export default LocationPage;
